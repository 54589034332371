import React from "react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

// Components
import { Link, graphql } from "gatsby"

const BlogList = ({ pageContext, data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Study site`
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 2
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "../" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  const title = `posts on page ${currentPage} | ${siteTitle}`
  const description = `Page ${currentPage} of many on ${siteTitle}`
  const posts = data.allMarkdownRemark.edges
  const path = `/page/${currentPage}/`

  return (
    <>
      <Layout location={location} title={siteTitle} bigHeading="true">
        <Seo title={title} description={description} pathname={path} />
        {/* <Bio /> */}
        <h1>Posts on page {currentPage}</h1>
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const postTitle =
              post.node.frontmatter.title || post.node.fields.slug

            return (
              <li key={post.node.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link to={post.node.fields.slug} itemProp="url">
                        <span itemProp="headline">{postTitle}</span>
                      </Link>
                    </h2>
                    <small>{post.node.frontmatter.updated}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          post.node.frontmatter.description ||
                          post.node.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
        <nav className="blog-post-nav">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {isFirst ? (
                <Link to="/" itemProp="url">
                  &#8592; Back to home
                </Link>
              ) : (
                <Link to={`/page/${prevPage}/`} rel="prev">
                  ← Newer posts
                </Link>
              )}
            </li>
            <li>
              {!isLast && (
                <Link to={`/page/${nextPage}/`} rel="next">
                  Older posts →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    </>
  )
}

export default BlogList

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___updated], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            updated(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
